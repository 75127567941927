<template>
  <auth-container
    title="비밀번호 재설정"
    :description="
      success ? '입력하신 휴대전화 번호로 링크가 전송되었습니다.' : '아래에 입력하신 휴대전화로 재설정 링크가 전송됩니다.'
    "
  >
    <auth-form :cancel_button="cancel_button" :submit_button="success ? null : submit_button">
      <auth-input
        v-if="!success"
        id="identity"
        type="text"
        placeholder="휴대폰 번호 입력"
        v-model="identity"
        @input="$v.identity.$touch()"
        :error_message="identity_error_message"
      />
    </auth-form>
  </auth-container>
</template>

<script>
import AuthContainer from '@/components/Auth/Container';
import AuthForm from '@/components/Auth/Form';
import AuthInput from '@/components/Auth/Input';

import { validationMixin } from 'vuelidate';
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  components: {
    AuthContainer,
    AuthForm,
    AuthInput,
  },

  data() {
    return {
      success: false,
      identity: '',
      cancel_button: {
        label: '로그인 화면으로',
        onClick: () => this.$router.push('/login'),
      },
    };
  },

  computed: {
    submit_button() {
      return {
        label: '비밀번호 변경 링크받기',
        disabled: this.identity === '' || this.$v.identity.$invalid,
        onClick: this.sendLink,
      };
    },

    identity_error_message() {
      if (isNaN(Number(this.identity))) return '숫자만 입력해주세요';
      return this.$v.identity.$dirty && !this.$v.identity.required ? '휴대폰 번호를 입력해주세요' : '';
    },
  },

  /** Form validation */
  mixins: [validationMixin],
  validations: {
    identity: { required, numeric, minLength: minLength(10), maxLength: maxLength(11) },
    password: { required },
  },

  methods: {
    async sendLink() {
      try {
        await this.$api.auth.sendPasswordLink(this.identity);
        this.success = true;
      } catch (error) {
        this.$utils.notify.error(this, '전송실패!', `휴대폰 번호를 확인해주세요.`);
      }
    },
  },
};
</script>
