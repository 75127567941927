<template>
  <section class="message-list">
    <div class="message-list__header">
      <TextInput
        style="width: 200px;"
        v-model="keyword"
        placeholder="회원명 or 제목"
        prefix-icon="el-icon-search"
        round
        @keyup.native.enter="handleChangeKeyword"
      />
    </div>

    <el-table
      v-loading="loading"
      :data="messages"
      :row-style="{ cursor: 'pointer', height: '40px' }"
      @row-click="handleClickRow"
      fit
    >
      <el-table-column label="발송시간" align="center" min-width="150">
        <template slot-scope="scope">
          {{ scope.row.send_on | datetime }}
        </template>
      </el-table-column>

      <el-table-column align="center" min-width="80">
        <template slot-scope="scope">
          <el-tag v-if="!scope.row.status" size="mini">예약</el-tag>
          <el-tag v-else-if="scope.row.status === 'canceled'" size="mini" type="danger">예약취소</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="메시지" header-align="center" min-width="400">
        <template slot-scope="scope">
          <p class="message-list__title">
            {{ scope.row.title }}
          </p>
        </template>
      </el-table-column>

      <el-table-column label="발송건수" align="center" min-width="100">
        <template slot-scope="scope"> {{ scope.row.targets_count | comma }}건 </template>
      </el-table-column>

      <el-table-column min-width="120" align="center">
        <template slot-scope="scope" v-if="!scope.row.status">
          <PlainButton size="small" @click.native.stop="handleClickEdit(scope.row.id)" :disabled="!canUpdateAppPushMessage"
            >수정</PlainButton
          >
          <PlainButton
            type="danger"
            size="small"
            style="margin-left: 4px;"
            @click.native.stop="handleClickCancel(scope.row)"
            :disabled="!canUpdateAppPushMessage"
            >취소</PlainButton
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="message-list__pagination"
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="limit"
      layout="prev, pager, next"
      :total="total"
    />
  </section>
</template>

<script>
export default {
  data() {
    return {
      keyword: '',
      loading: false,
      messages: [],
      total: 0,
      limit: 10,
      page: 1,
    };
  },

  mounted() {
    this.getMessages();
  },

  methods: {
    handlePageChange(page) {
      this.$router.replace({
        path: '/message/list',
        query: {
          ...this.$route.query,
          page,
        },
      });
    },

    handleChangeKeyword() {
      this.$router.replace({
        path: '/message/list',
        query: {
          ...this.$route.query,
          keyword: this.keyword,
          page: 1,
        },
      });
    },

    async getMessages() {
      this.loading = true;
      const page = this.$route.query.page ? +this.$route.query.page : 1;
      const keyword = this.$route.query.keyword ?? '';
      const params = { limit: this.limit, page, keyword };

      try {
        const res = await this.$api.message.getAll(params);
        this.total = res.data.total;
        this.messages = res.data.data;
        this.page = page;
        this.keyword = keyword;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
      }
    },

    handleClickRow(row) {
      this.$router.push({
        path: `/message/${row.id}`,
        query: {
          keyword: this.keyword,
        },
      });
    },

    handleClickEdit(messageId) {
      this.$router.push(`/message/${messageId}/edit`);
    },

    async handleClickCancel(row) {
      const title = '예약 메시지 취소';
      const sendOn = this.moment(row.send_on).format('llll');
      const count = this.$filters.comma(row.targets_count);
      const message = `${sendOn} ${row.title} ${count}건의 예약 메시지를 취소하시겠습니까?`;

      const confirmed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);

      if (confirmed) {
        try {
          await this.$api.message.update({
            id: row.id,
            status: 'canceled',
          });
          this.$utils.notify.success(this, null, '예약 메시지가 취소되었습니다.');
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        } finally {
          this.getMessages();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-list {
  @include flex(column, center, center);

  &__header {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    &__selects {
      @include flex(row, center, flex-start);
      /deep/ .el-select {
        width: 190px;
        margin-right: 8px;
      }

      /deep/ .el-input__inner {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        height: 40px;
        padding: 0 15px;
        color: #5d646b;
      }

      /deep/ .el-date-editor {
        max-width: 230px;
      }

      /deep/ .el-range-separator {
        line-height: 3;
      }

      /deep/ .el-range-input:nth-child(2) {
        margin-left: 10px;
      }

      /deep/ .el-range__close-icon {
        display: none;
      }

      p {
        color: #64aeff;
        font-weight: bold;
        font-size: 16px;
        margin-left: 20px;
      }
    }
  }

  &__title {
    @include ellipsis;
  }

  &__pagination {
    margin-top: 20px;
  }
}
</style>
