<template>
  <auth-container :title="success ? '계정이 생성되었습니다.' : ''" show_logo>
    <auth-form v-if="!success" :submit_button="submit_button">
      <auth-input
        id="name"
        type="text"
        label="이름"
        placeholder="본인 이름을 입력해주세요"
        v-model="name"
        @input="$v.name.$touch()"
        info_message="시설에 등록한 강사명을 입력해주세요."
        :error_message="name_error_message"
      />
      <auth-input
        id="identity"
        type="text"
        label="휴대폰 번호"
        placeholder="휴대폰 번호 입력"
        v-model="identity"
        @input="$v.identity.$touch()"
        :error_message="identity_error_message"
      />
      <auth-input
        id="password"
        type="password"
        label="비밀번호"
        placeholder="영문, 숫자를 포함한 8~32자리 비밀번호 입력"
        v-model="password"
        @input="$v.password.$touch()"
        :error_message="password_error_message"
      />
      <auth-input
        id="password_confirmation"
        type="password"
        label="비밀번호 확인"
        placeholder="비밀번호 한번 더 입력"
        v-model="password_confirmation"
        @input="$v.password_confirmation.$touch()"
        :error_message="password_confirmation_error_message"
      />
    </auth-form>
    <custom-footer />
  </auth-container>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, numeric, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
/** Components */
import AuthContainer from '@/components/Auth/Container';
import AuthForm from '@/components/Auth/Form';
import AuthInput from '@/components/Auth/Input';
import CustomFooter from '@/components/Auth/Footer';

const pwValidation = value => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*?()-=_+[\]{};':",./<>?`~\\|]{1,}$/.test(value);

export default {
  components: {
    AuthContainer,
    AuthForm,
    AuthInput,
    CustomFooter,
  },

  data() {
    return {
      name: '',
      identity: '',
      password: '',
      password_confirmation: '',

      saving: false,
      success: false,
    };
  },

  computed: {
    submit_button() {
      let label = '스태프 계정 만들기';
      let onClick = this.createStaffAccount;
      const disabled =
        this.saving ||
        this.$v.name.$invalid ||
        this.$v.identity.$invalid ||
        this.$v.password.$invalid ||
        this.$v.password_confirmation.$invalid;

      return {
        label,
        disabled,
        loading: this.saving,
        onClick,
      };
    },

    name_error_message() {
      return this.$v.name.$dirty && !this.$v.name.required ? '이름을 입력해주세요' : '';
    },

    identity_error_message() {
      if (isNaN(Number(this.identity))) return '숫자만 입력해주세요';
      return this.$v.identity.$dirty && !this.$v.identity.required ? '휴대폰 번호를 입력해주세요' : '';
    },

    password_error_message() {
      let message = '';
      if (this.$v.password.$dirty && !this.$v.password.required) {
        message = '비밀번호를 입력해주세요';
      } else if (
        this.$v.password.$dirty &&
        (!this.$v.password.minLength || !this.$v.password.maxLength || !this.$v.password.pwValidation)
      ) {
        message = '비밀번호는 영문, 숫자를 1개 이상 포함하여 8-32자리로 입력해주세요';
      }

      return message;
    },

    password_confirmation_error_message() {
      let message = '';

      if (this.$v.password_confirmation.$dirty && !this.$v.password_confirmation.required) {
        message = '비밀번호를 한번 더 입력해주세요';
      } else if (this.$v.password_confirmation.$dirty && !this.$v.password_confirmation.sameAs) {
        message = '비밀번호를 동일하게 입력해주세요';
      }

      return message;
    },
  },

  /** Form validation */
  mixins: [validationMixin],
  validations: {
    name: {
      required,
    },
    identity: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(11),
    },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(32),
      pwValidation,
    },
    password_confirmation: {
      required,
      sameAs: sameAs('password'),
    },
  },

  methods: {
    async createStaffAccount() {
      const token = this.$route.params.token;
      const data = {
        name: this.name,
        mobile: this.identity,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };

      try {
        this.saving = true;
        await this.$api.auth.createAccount(token, data);
        this.success = true;
        window.location.replace('https://www.studiomate.kr/app_manager');
      } catch (error) {
        if (error.response.status === 404) {
          this.$utils.notify.error(this, '오류', '유효하지 않은 링크입니다<br>시설에 다시 요청해주세요');
        } else if (error.response.status === 400) {
          this.$utils.notify.error(this, '오류', '이름이 잘못 입력되었습니다<br>시설에 등록된 이름을 입력해주세요');
        } else if (error.response.status === 422) {
          this.$utils.notify.error(this, '오류', '휴대폰 번호, 비밀번호를 다시 입력해 주세요.');
        } else {
          this.$utils.notify.parseError(this, error);
        }
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
