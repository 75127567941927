<template>
  <el-card class="staff-card" shadow="hover" :body-style="card_body_style" :style="{ borderTop: `2px solid ${staff_color}` }">
    <div class="staff-card__contents" @click="handleClickCard">
      <div class="staff-card__contents__tags">
        <span v-if="staff.roles.length">
          {{ staff.roles[0].display_name }}
        </span>
        <span v-else class="staff-card__contents__tags__no-role">
          역할지정 필요
        </span>
        <span v-if="isMe" class="staff-card__contents__tags__me">
          Me
        </span>
      </div>
      <img
        class="staff-card__contents__image"
        :src="$utils.getImageUrl(staff.avatars[0], '90x90')"
        :alt="`${staff.profile.name} 프로필 사진`"
      />
      <p class="staff-card__contents__name">
        {{ staff.profile.name }}
      </p>
      <p class="staff-card__contents__mobile">
        {{ staff_mobile | mobile }}
      </p>

      <div class="staff-card__contents__account">
        <button v-if="staff.account_id !== null" @click.stop="handleAccountAction">
          {{ isMe ? '비밀번호 재설정' : '비밀번호 초기화' }}
        </button>
      </div>
    </div>

    <div class="staff-card__buttons">
      <button v-if="canDeleteStaff && !isMe" class="staff-card__buttons__delete" @click="deleteStaff(staff)">
        삭제
      </button>
      <button v-if="canUpdateStaff" class="staff-card__buttons__edit" @click="$router.push(`/staffs/edit?id=${staff.id}`)">
        수정
      </button>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    staff: Object,
    deleteStaff: Function,
    resetPassword: Function,
  },

  data() {
    return {
      card_body_style: {
        width: '100%',
        height: '100%',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    };
  },

  computed: {
    isMe() {
      return this.currentUser.id === this.staff.id;
    },

    staff_color() {
      const { representative_color } = this.staff.profile;
      return representative_color ? `#${representative_color}` : '#000';
    },

    staff_mobile() {
      const { contact_infos } = this.staff;
      return contact_infos.length ? contact_infos[0].contact : '';
    },
  },

  methods: {
    handleClickCard() {
      if (this.isMe) {
        this.$router.push('/staffs/me');
      } else {
        this.$router.push(`/staffs/detail?id=${this.staff.id}`);
      }
    },
    handleAccountAction() {
      this.resetPassword(this.staff);
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 224px;
  height: 326px;
  border-radius: 3px;
  background-color: #ffffff;

  &__contents {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px 16px 16px;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &__tags {
      display: flex;
      justify-content: space-between;
      width: 100%;

      span {
        object-fit: contain;
        border-radius: 2px;
        background-color: #e6e6e6;
        font-size: 10px;
        line-height: 1;
        padding: 4px;
        color: $charcoal-grey;

        &.staff-card__contents__tags__no-role {
          background-color: $color-danger;
          color: #fff;
        }

        &.staff-card__contents__tags__me {
          background-color: $aqua-marine;
          color: #fff;
        }
      }
    }

    &__image {
      width: 90px;
      height: 90px;
      box-shadow: 0 8px 12px -8px rgba(52, 58, 64, 0.2);
      border-radius: 50%;
      margin: 10px 0 15px;
    }

    &__name {
      @include ellipsis;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.5;
      color: $charcoal-grey;
      margin-bottom: 8px;
      width: 100%;
      text-align: center;
    }

    &__mobile {
      opacity: 0.7;
      font-size: 12px;
      font-weight: 500;
      color: $charcoal-grey;
      margin-bottom: 14px;
    }

    &__account {
      margin-top: 10px;

      p {
        grid-row: 1;
        font-size: 12px;
        color: $charcoal-grey;
        margin-bottom: 12px;
      }

      button {
        grid-row: 2;
        width: 86px;
        height: 24px;
        font-size: 10px;
        line-height: 1;
        border: 1px solid $deep-sky-blue;
        border-radius: 2px;
        color: $deep-sky-blue;
        transition: 0.15s;

        &:hover {
          background: $deep-sky-blue;
          color: #fff;
        }
      }
    }
  }

  &__buttons {
    justify-self: flex-end;
    display: flex;
    justify-content: flex-end;
    padding: 8px 15px 7px;
    border-radius: 2px;
    background-color: rgba(#ebebeb, 0.3);
    min-height: 39px;

    button {
      width: 43px;
      height: 24px;
      border-radius: 2px;
      font-size: 10px;
      font-weight: bold;
      color: #fff;
      transition: 0.15s;

      & + button {
        margin-left: 8px;
      }
    }

    &__delete {
      background: $color-danger;

      &:hover {
        background: #f64343;
      }
    }

    &__edit {
      background: $deep-sky-blue;

      &:hover {
        background: #1a87ff;
      }
    }
  }
}
</style>
