<template>
  <auth-container
    footer_message="비밀번호를 잊으셨나요?"
    :footer_links="[{ path: '/reset_password', label: '비밀번호 재설정' }]"
    show_logo
  >
    <auth-form :submit_button="submit_button" v-loading="loggingIn">
      <auth-input
        id="identity"
        type="text"
        placeholder="휴대폰 번호"
        v-model="identity"
        @input="$v.identity.$touch()"
        :error_message="identity_error_message"
      />
      <auth-input
        id="password"
        type="password"
        placeholder="비밀번호"
        v-model="password"
        @input="$v.password.$touch()"
        :error_message="password_error_message"
      />
    </auth-form>
  </auth-container>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
/** Components */
import AuthContainer from '@/components/Auth/Container';
import AuthForm from '@/components/Auth/Form';
import AuthInput from '@/components/Auth/Input';

export default {
  components: {
    AuthContainer,
    AuthForm,
    AuthInput,
  },

  data() {
    return {
      identity: '',
      password: '',
      loggingIn: false,
    };
  },

  computed: {
    submit_button() {
      return {
        label: '로그인',
        disabled: this.$v.identity.$invalid || this.$v.password.$invalid,
        onClick: this.loginUser,
      };
    },

    identity_error_message() {
      if (isNaN(Number(this.identity))) return '숫자만 입력해주세요';
      return this.$v.identity.$dirty && !this.$v.identity.required ? '휴대폰 번호를 입력해주세요' : '';
    },

    password_error_message() {
      return this.$v.password.$dirty && !this.$v.password.required ? '비밀번호를 입력해주세요' : '';
    },
  },

  async created() {
    // /** 브라우저 확인 */
    // const { is_mobile, is_chrome } = this.$utils.checkBrowser()

    // if (is_mobile) {
    //   alert("스튜디오메이트는 PC 웹에 최적화 되어 있습니다. PC에서 크롬 브라우저를 사용하시기 바랍니다.")
    // } else if (!is_chrome) {
    //   alert("스튜디오메이트는 크롬 브라우저‌에 최적화 되어 있습니다. 크롬 브라우저를 통해 사용하시기 바랍니다")
    // }

    const { superLogin, token, userId, redirect } = this.$route.query;

    if (superLogin && token && userId) {
      const subdomain = window.location.hostname.split('.')[0];
      const data = { superLogin, token, userId, subdomain };
      await this.$store.dispatch('auth/superLogin', data);
      this.$router.push(redirect ? redirect : { name: 'schedule' });
    }
  },

  /** Form validation */
  mixins: [validationMixin],
  validations: {
    identity: { required },
    password: { required },
  },

  methods: {
    async loginUser() {
      this.loggingIn = true;
      const payload = {
        mobile: this.identity,
        password: this.password,
        vm: this,
      };

      await this.$store.dispatch('auth/login', payload);
      this.$router.push({ name: 'schedule' });
      this.loggingIn = false;
    },
  },
};
</script>
