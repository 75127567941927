<template>
  <MainLayout>
    <section class="staffs__header">
      <div class="staffs__header__title">
        <h3>강사관리</h3>
        <h5>총 {{ total | comma }}명</h5>
      </div>

      <TextInput
        class="staffs__header__filter"
        v-model="search"
        placeholder="이름, 휴대폰번호 검색"
        prefix-icon="el-icon-search"
        round
        @enter="handleChangeSearchInput"
      />
    </section>

    <section class="staffs__contents" v-loading="loading">
      <ul class="staffs__contents__staff-list">
        <staff-card
          v-for="staff in staffs"
          :key="staff.id"
          :staff="staff"
          :deleteStaff="deleteStaff"
          :resetPassword="handleResetPasswordClick"
        />
      </ul>

      <el-pagination
        layout="prev, pager, next"
        @current-change="dispatchGetStaffList"
        :current-page.sync="page"
        :page-size="per_page"
        :total="total"
      />
    </section>

    <PasswordResetModal
      v-if="showPasswordResetModal"
      :open="showPasswordResetModal"
      :onClose="
        () => {
          showPasswordResetModal = false;
        }
      "
    />

    <floating-action-button v-if="canCreateStaff" @click="$router.push('/staffs/create')" />
  </MainLayout>
</template>

<script>
import StaffCard from '@/components/Staffs/StaffCard';
import PasswordResetModal from '@/components/Modals/PasswordReset';

export default {
  components: {
    StaffCard,
    PasswordResetModal,
  },

  data() {
    return {
      page: 1,
      per_page: 0,
      search: '',
      showPasswordResetModal: false,
    };
  },

  computed: {
    loading() {
      return this.$store.getters['staffs/loading'];
    },
    staffs() {
      return this.$store.getters['staffs/staffs'];
    },
    total() {
      return this.$store.getters['staffs/total'];
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.canViewStaff) {
        vm.$utils.notify.error(vm, '권한이 없습니다.');
        return next('/');
      }

      next();
    });
  },

  created() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  },

  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
  },

  methods: {
    handleChangeSearchInput() {
      this.page = 1;
      this.dispatchGetStaffList();
    },

    handleWindowResize() {
      let per_page = 5;
      const browserWidth = window.innerWidth;
      if (568 <= browserWidth && browserWidth < 768) per_page = 4;
      else if (768 <= browserWidth && browserWidth < 1024) per_page = 6;
      else if (1024 <= browserWidth && browserWidth < 1280) per_page = 8;
      else if (1280 <= browserWidth && browserWidth < 1500) per_page = 10;
      else if (1500 <= browserWidth) per_page = 12;

      if (this.per_page !== per_page) {
        this.per_page = per_page;
        this.dispatchGetStaffList();
      }
    },

    dispatchGetStaffList() {
      const params = {
        search: this.search,
        page: this.page,
        per_page: this.per_page,
        with: 'account',
      };

      this.$store.dispatch('staffs/getStaffs', params);
    },

    /** 강사 삭제 */
    deleteStaff(staff) {
      const title = '강사 삭제';
      const message = `
          <h4>${staff.profile.name} 강사님을 삭제하시겠습니까?</h4>
          <p style="margin-top: 1em; color: #f56c6c">
            담당 강사로 지정된 그룹 클래스가 있는 경우 강사를 삭제할 수 없습니다. <br>
            (단, 프라이빗 수업 제외)
          </p>
        `;
      this.$confirm(message, title, {
        confirmButtonText: '삭제',
        cancleButtonText: '취소',
        dangerouslyUseHTMLString: true,
      })
        .then(async () => {
          try {
            await this.$api.staff.delete(staff.id);
            this.$utils.notify.success(this, '강사 삭제', `${staff.profile.name} 강사가 삭제되었습니다.`);
            this.dispatchGetStaffList();
          } catch (error) {
            const code = this._.get(error, 'response.data.code');
            const title = '강사 삭제 실패';
            let message = '담당 강사로 지정된 수강권이 있습니다.';

            const errorAlert = (alertMessage, alertTitle, type) => {
              return this.$alert(alertMessage, alertTitle).then(() => {
                this.$router.push(`/staffs/detail?id=${staff.id}&tab=${type}`);
              });
            };
            if (code === 10607) {
              return errorAlert(message, title, 'members');
            }

            if (code === 10613) {
              let message = '담당 강사로 지정된 그룹 클래스가 있습니다.';
              return errorAlert(message, title, 'classes');
            }

            this.$utils.notify.parseError(this, error);
          }
        })
        .catch(() => {});
    },

    /**
     * 비밀번호 재설정
     * 1. 본인일 경우: 모달에서 바로 변경
     * 2. 다른 강사일 경우: 비밀번호 초기화
     *
     */
    async handleResetPasswordClick(staff) {
      const is_me = staff.id === this.currentUser.id;

      if (is_me) {
        this.showPasswordResetModal = true;
      } else {
        try {
          await this.$api.auth.resetPassword(staff.id);
          this.$utils.notify.success(this, '성공', '비밀번호가 초기화 되었습니다.');
        } catch (error) {
          this.$utils.notify.error(this, '실패', '비밀번호 초기화가 정상적으로</br> 되지 않았습니다.');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.staffs__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;

  &__title {
    display: flex;
    align-items: flex-end;

    h3 {
      font-size: 28px;
      font-weight: bold;
      letter-spacing: normal;
    }

    h5 {
      font-size: 15px;
      font-weight: 500;
      margin-left: 31px;
    }
  }

  &__filter {
    width: 192px !important;
    height: 34px;
  }
}

.staffs__contents {
  @include flex(column, center);

  &__staff-list {
    display: grid;
    grid-template-columns: 224px;
    grid-gap: 24px;
    place-items: center;
    padding: 28px 0;

    @include mq(568px) {
      grid-template-columns: repeat(2, 224px);
    }

    @include mq(768px) {
      grid-template-columns: repeat(3, 224px);
    }

    @include mq(1024px) {
      grid-template-columns: repeat(4, 224px);
    }

    @include mq(1280px) {
      grid-template-columns: repeat(5, 224px);
    }

    @include mq(1500px) {
      grid-template-columns: repeat(6, 224px);
    }
  }
}
</style>
