<template>
  <auth-container :title="success ? '비밀번호 변경 완료' : '비밀번호 재설정'" show_logo>
    <auth-form v-if="!success" :submit_button="submit_button">
      <auth-input
        id="identity"
        type="text"
        placeholder="휴대폰 번호 입력"
        v-model="identity"
        @input="$v.identity.$touch()"
        :error_message="identity_error_message"
      />
      <auth-input
        id="password"
        type="password"
        placeholder="영문, 숫자를 포함한 8~32자리 비밀번호 입력"
        v-model="password"
        @input="$v.password.$touch()"
        :error_message="password_error_message"
      />
      <auth-input
        id="password_confirmation"
        type="password"
        placeholder="비밀번호 한번 더 입력"
        v-model="password_confirmation"
        @input="$v.password_confirmation.$touch()"
        :error_message="password_confirmation_error_message"
      />
    </auth-form>
    <custom-footer />
  </auth-container>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, numeric, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
/** Components */
import AuthContainer from '@/components/Auth/Container';
import AuthForm from '@/components/Auth/Form';
import AuthInput from '@/components/Auth/Input';
import CustomFooter from '@/components/Auth/Footer';

const alphaNum = value => /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,32}$/.test(value);

export default {
  components: {
    AuthContainer,
    AuthForm,
    AuthInput,
    CustomFooter,
  },

  data() {
    return {
      identity: '',
      password: '',
      password_confirmation: '',

      success: false,
      link_invalid: false,
    };
  },

  computed: {
    submit_button() {
      let label = '비밀번호 변경';
      let onClick = this.resetPassword;
      const disabled = this.$v.identity.$invalid || this.$v.password.$invalid || this.$v.password_confirmation.$invalid;

      if (this.link_invalid) {
        label = '링크 받으러 가기';
        onClick = this.redirectToPasswordResetPage;
      }

      return {
        label,
        disabled,
        onClick,
      };
    },

    identity_error_message() {
      if (isNaN(Number(this.identity))) return '숫자만 입력해주세요';
      return this.$v.identity.$dirty && !this.$v.identity.required ? '휴대폰 번호를 입력해주세요' : '';
    },

    password_error_message() {
      let message = '';

      if (this.$v.password.$dirty && !this.$v.password.required) {
        message = '비밀번호를 입력해주세요';
      } else if (
        this.$v.password.$dirty &&
        (!this.$v.password.minLength || !this.$v.password.maxLength || !this.$v.password.alphaNum)
      ) {
        message = '비밀번호는 영문, 숫자를 포함하여 8-32자리로 입력해주세요';
      }

      return message;
    },

    password_confirmation_error_message() {
      let message = '';

      if (this.$v.password_confirmation.$dirty && !this.$v.password_confirmation.required) {
        message = '비밀번호를 한번 더 입력해주세요';
      } else if (this.$v.password_confirmation.$dirty && !this.$v.password_confirmation.sameAs) {
        message = '비밀번호를 동일하게 입력해주세요';
      }

      return message;
    },
  },

  /** Form validation */
  mixins: [validationMixin],
  validations: {
    identity: { required, numeric, minLength: minLength(10), maxLength: maxLength(11) },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(32),
      alphaNum,
    },
    password_confirmation: {
      required,
      sameAs: sameAs('password'),
    },
  },

  methods: {
    async resetPassword() {
      const token = this.$route.params.token;
      const data = {
        mobile: this.identity,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };

      try {
        await this.$api.auth.updatePassword(token, data);
        this.success = true;
        window.location.replace('https://www.studiomate.kr/app_manager');
      } catch (error) {
        if (error.response.status === 404) {
          this.$utils.notify.error(this, '오류', '유효하지 않은 링크입니다.');
          this.link_invalid = true;
        } else {
          this.$utils.notify.error(this, '오류', '휴대폰 번호, 비밀번호를 다시 입력해 주세요.');
        }
      }
    },

    redirectToPasswordResetPage() {
      this.$router.push('/reset_password');
    },
  },
};
</script>
