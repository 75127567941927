import axios from '../axios';

const BASE_URL = '/v2/staff/etcSchedule';

export default {
  // 기타일정 목록
  getAll: params =>
    axios.get(BASE_URL, {
      params,
    }),

  // 기타일정 목록 count
  getCount: params =>
    axios.get(`${BASE_URL}/count`, {
      params,
    }),

  // 기타일정 check
  getCheck: params =>
    axios.get(`${BASE_URL}/check`, {
      params,
    }),
};
