<template>
  <section class="message-list-container" v-loading="loadingCancel">
    <SmsMessageWarning />

    <div class="message-list">
      <div class="message-list__header">
        <div class="message-list__header__selects">
          <el-select v-model="smsType" placeholder="메시지 전체" filterable :class="{ 'is-filtered': smsType !== 'ALL' }">
            <el-option v-for="option in smsTypeOptions" :key="option.label" :value="option.value" :label="option.label">
              {{ option.label }}
            </el-option>
          </el-select>
          <el-select v-model="reserveType" placeholder="메시지 전체" filterable :class="{ 'is-filtered': reserveType !== null }">
            <el-option v-for="option in reserveTypeOptions" :key="option.label" :value="option.value" :label="option.label">
              {{ option.label }}
            </el-option>
          </el-select>
          <el-date-picker
            v-model="daterange"
            type="daterange"
            range-separator="-"
            start-placeholder="이용 시작일"
            end-placeholder="이용 종료일"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :clearable="false"
            required
          />
          <p>발송건수 : {{ sendCount }}건</p>
          <!-- <p>잔여포인트 : {{ point | comma }}P</p> -->
        </div>

        <TextInput
          style="width: 200px;"
          v-model="params.title"
          placeholder="회원명 or 제목"
          prefix-icon="el-icon-search"
          round
          @keyup.native.enter="getMessages"
        />
      </div>

      <el-table
        v-loading="loadingMessages"
        :data="messages"
        :row-style="{ cursor: 'pointer', height: '40px' }"
        @row-click="handleClickRow"
        fit
      >
        <el-table-column label="발송시간" align="center" min-width="150">
          <template slot-scope="scope">
            {{ scope.row.send_on | datetime }}
          </template>
        </el-table-column>

        <el-table-column align="center" min-width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.deleted_at" size="mini" type="danger">예약취소</el-tag>
            <el-tag v-else-if="!scope.row.is_sent && scope.row.is_processing" size="mini" type="warning">처리중</el-tag>
            <el-tag v-else-if="scope.row.is_sent === 0" size="mini">예약</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="메시지" header-align="center" min-width="245">
          <template slot-scope="scope">
            <p class="message-list__title">
              {{ scope.row.title }}
            </p>
          </template>
        </el-table-column>

        <el-table-column label="종류" header-align="center" min-width="50">
          <template slot-scope="scope">
            <p class="message-list__sms-type">
              {{ scope.row.sms_type }}
            </p>
          </template>
        </el-table-column>

        <el-table-column label="받는 사람" align="center" min-width="180">
          <template slot-scope="scope">
            {{
              scope.row.targets.length === 0
                ? `${scope.row.targets.length} 명`
                : scope.row.targets.length === 1
                ? scope.row.targets[0].name
                : `${scope.row.targets[0].name} 외 ${scope.row.targets.length - 1}명`
            }}
          </template>
        </el-table-column>

        <el-table-column label="발송 건수" header-align="center" min-width="0=80">
          <template slot-scope="scope">
            <p class="message-list__sms-send-length">{{ scope.row.targets_count }} 건</p>
          </template>
        </el-table-column>

        <el-table-column header-align="center" align="center" min-width="120">
          <template slot="header">
            <p>
              처리현황
            </p>
            <span style="font-size: 10px;">
              <span style="color: #69de35;">성공</span>
              <span> / </span>
              <span style="color: #fd9f9f;">실패</span>
              <span> / </span>
              <span style="color: #cbcbcb;">처리 중</span>
            </span>
          </template>

          <template slot-scope="scope">
            <span style="color: #69de35;">{{ scope.row.targets_count }}</span>
            <span> / </span>
            <span style="color: #fd9f9f;">{{ scope.row.fail_count }}</span>
            <span> / </span>
            <span style="color: #cbcbcb;">{{ scope.row.process_count }}</span>
            <p style="font-size: 10px;">
              <span style="color: #cbcbcb;">차감</span>
              <span>{{ ` ${scope.row.deduction_point}P` }}</span>
            </p>
          </template>
        </el-table-column>

        <el-table-column label="발송 결과" align="center" min-width="120">
          <template slot-scope="scope">
            <PlainButton v-if="scope.row.is_sent" size="small" @click.native.stop="handleClickDetail(scope.row)"
              >결과 보기</PlainButton
            >
            <PlainButton
              class="message-list__column-button"
              v-if="!scope.row.is_sent && !scope.row.deleted_at && !scope.row.is_processing"
              size="middle"
              @click.native.stop="handleClickEdit(scope.row.id)"
              :disabled="!canUpdateSmsMessage"
              >수정</PlainButton
            >
            <PlainButton
              v-if="!scope.row.is_sent && !scope.row.deleted_at && !scope.row.is_processing"
              type="danger"
              size="middle"
              style="margin-left: 4px;"
              @click.native.stop="handleClickCancel(scope.row)"
              :disabled="!canUpdateSmsMessage"
              >취소</PlainButton
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="message-list__pagination"
        @current-change="getMessages"
        :current-page.sync="params.page"
        :page-size="params.limit"
        layout="prev, pager, next"
        :total="total"
      />

      <SmsDetailList :show="showDetailModal" @close="handleDetailListClose" :detailData="detailData" />
    </div>
  </section>
</template>

<script>
import SmsDetailList from '@/components/Modals/SmsDetailList';
import SmsMessageWarning from './SmsMessageWarning.vue';

export default {
  components: { SmsDetailList, SmsMessageWarning },
  data() {
    const { moment } = this;
    return {
      loadingMessages: false,
      loadingCancel: false,
      params: { page: 1, limit: 10, title: '' },
      messages: [],
      total: 0,
      smsType: 'ALL',
      reserveType: null,
      smsTypeOptions: [
        { value: 'ALL', label: '메시지 종류 전체' },
        { value: 'SMS', label: 'SMS' },
        { value: 'LMS', label: 'LMS' },
      ],
      reserveTypeOptions: [
        { value: null, label: '메시지 상태 전체' },
        { value: 1, label: '예약문자' },
        { value: 0, label: '발송문자' },
      ],
      showDetailModal: false,
      detailData: null,
      daterange: [moment().format('YYYY-MM-01'), moment().format('YYYY-MM-DD')],
    };
  },

  created() {
    this.getMessages();
    this.sendCount = this.$store.state.sms.smsSendCount;
  },

  computed: {
    activeTab() {
      return this.$route.name;
    },
    point() {
      return this.$store.getters['payment/point'];
    },
  },
  watch: {
    smsType() {
      this.params = { page: 1, limit: 10, title: this.params.title };
      this.getMessages();
    },
    reserveType() {
      this.params = { page: 1, limit: 10, title: this.params.title };
      this.getMessages();
    },
    daterange() {
      this.params = { page: 1, limit: 10, title: this.params.title };
      this.getMessages();
    },
  },

  methods: {
    async getMessages() {
      this.loadingMessages = true;
      const typeConverter = this.smsType === 'ALL' ? ['SMS', 'LMS'] : this.smsType === 'SMS' ? ['SMS'] : ['LMS'];
      try {
        const params = {
          sms_type: typeConverter,
          ...this.params,
          is_sent: this.reserveType,
          start_date: this.daterange[0],
          end_date: this.daterange[1],
        };
        await this.$store.dispatch('sms/getSMSList', params);
        this.messages = _.get(this.$store.state.sms, 'smsList');
        this.total = this.$store.state.sms.smsTotalNum;
        this.sendCount = this.$store.state.sms.smsSendCount;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
        this.messages = [];
        this.total = 0;
      } finally {
        this.loadingMessages = false;
      }
    },

    handleClickRow(row) {
      if (this.params.title) {
        return this.$router.push(`/sms/${row.id}?sms_type=${this.smsType}&search=${this.params.title}`);
      } else {
        return this.$router.push(`/sms/${row.id}?sms_type=${this.smsType}`);
      }
    },

    handleClickDetail(row) {
      this.detailData = row;
      return (this.showDetailModal = true);
    },

    handleClickEdit(messageId) {
      this.$router.push(`/sms/${messageId}/edit`);
    },

    async handleClickCancel(row) {
      const title = '예약 메시지 취소';
      const sendOn = this.moment(row.send_on).format('llll');
      const count = this.$filters.comma(row.targets.length);
      const message = `${sendOn} ${row.title ? row.title : '제목없음'} ${count}건의 예약 메시지를 취소하시겠습니까?`;
      const confirmed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);
      if (confirmed) {
        try {
          this.loadingCancel = true;
          await this.$api.sms.cancel(row.id);
          this.$utils.notify.success(this, null, '예약 메시지가 취소되었습니다.');
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        } finally {
          this.loadingCancel = false;
          this.$store.dispatch('payment/getPointScore');
          this.getMessages();
        }
      }
    },

    handleDetailListClose(close) {
      this.showDetailModal = close;
    },
  },
};
</script>

<style lang="scss" scoped>
.messages__body > section.message-list-container {
  padding: 20px 0 40px;
}

.message-list {
  @include flex(column, center, center);

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

    &__selects {
      @include flex(row, center, flex-start);
      /deep/ .el-select {
        width: 140px;
        margin-right: 8px;
      }

      /deep/ .el-input__inner {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        height: 40px;
        padding: 0 15px;
        color: #5d646b;
      }

      /deep/ .el-date-editor {
        max-width: 230px;
      }

      /deep/ .el-range-separator {
        line-height: 3;
      }

      /deep/ .el-range-input:nth-child(2) {
        margin-left: 10px;
      }

      /deep/ .el-range__close-icon {
        display: none;
      }

      p {
        color: #64aeff;
        font-weight: bold;
        font-size: 16px;
        margin-left: 20px;
      }
    }
  }

  &__title {
    @include ellipsis;
  }

  &__sms-type,
  &__sms-send-length {
    text-align: center;
  }

  &__pagination {
    margin-top: 20px;
  }

  &__column-button {
    margin-right: 8px;
  }

  .is-filtered {
    color: $color-primary;

    /deep/ .el-input__inner {
      color: $color-primary;
    }
  }
}
</style>
