<template>
  <div class="message-list-warning">
    <img src="@/assets/images/icons/icon_warning.svg" alt="warning" />
    <div class="text-wrapper">
      <p class="red">
        1. 문자 발송 시 통신사 사정에 의해 최대 72시간까지 발송결과에 대한 응답 대기 시간이 소요될 수 있습니다.
      </p>
      <p class="red">
        2. 응답 대기 중인 메시지는 '처리중..'으로 표시되며 통신사 응답이 완료되면 '전송 성공'으로 변경되고 '전송 실패'에 한해
        차감된 포인트는 환불됩니다.
      </p>
      <p>
        3. 제목은 40byte까지만 입력되어야 하며 한글, 영어, 숫자, 띄어쓰기 및 (), [], &lt; &gt;만 입력할 것을 권장합니다. 이 외
        특수 기호 사용 시 전송이 실패될 수 있습니다.
      </p>
      <p>
        4. 다음과 같은 경우, 기타 에러로 표시되어 전송에 실패할 수 있습니다.
      </p>
      <p class="gray">■ 음영 지역, 잘못된 번호, 통신사 수신 거부, 일반 수신 거부, 네크워트 에러</p>
      <p class="gray">■ 수신 번호를 찾을 수 없음, 단말기 일시정지, LMS 미지원 단말기 등 기타 사유</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.message-list-warning {
  margin-bottom: 30px;
  position: relative;

  img {
    position: absolute;
    top: 17px;
    left: 11px;
    width: 36px;
    height: 36px;
    z-index: 1;
  }

  .text-wrapper {
    padding: 15px 25px 15px 82px;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    background-color: #f6f6f6;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    &:before {
      content: '';
      width: 57px;
      height: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 1px soild #e33c3c;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #e33c3c;
    }
  }

  p {
    font-weight: bold;
    font-size: 13px;
    line-height: 1.8;
  }

  .red {
    color: #bb0000;
  }

  .gray {
    font-weight: normal;
    color: #777777;
    line-height: 1.5;
  }
}
</style>
